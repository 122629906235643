import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@makeably/creativex-design-system';
import Filter, {
  optionProps,
  segmentProps,
} from 'components/molecules/Filter';
import { byObjectValue } from 'utilities/sort';
import {
  EMPTY_LABEL,
  EMPTY_VALUE,
} from './utilities';

export const filterProps = {
  isOpen: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(optionProps).isRequired,
  segments: PropTypes.arrayOf(segmentProps).isRequired,
  selections: PropTypes.objectOf(
    PropTypes.arrayOf(optionProps),
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onSelectionsChange: PropTypes.func.isRequired,
};

const propTypes = PropTypes.shape(filterProps).isRequired;

function getRecordOption(record, key) {
  const value = record[key] ?? EMPTY_VALUE;
  const label = record[key] ?? EMPTY_LABEL;

  return {
    label,
    value,
  };
}

function addOptions(uniques, optionObj) {
  const optionObjArray = Array.isArray(optionObj) ? optionObj : [optionObj];

  return optionObjArray.forEach(({ value, label }) => (
    uniques.set(value, {
      label,
      value,
    })
  ));
}

function addArrayOptions(optionObj, uniques) {
  return optionObj.value.forEach((obj) => addOptions(uniques, {
    label: obj,
    value: obj,
  }));
}

function getUniqueOptions(records, key, getCustomOption) {
  // @note: in testing, using Map is faster than using unique keys in an object
  const uniques = new Map();

  records.map((record) => {
    const optionObj = getCustomOption?.(record, key) ?? getRecordOption(record, key);
    if (Array.isArray(optionObj.value)) {
      return addArrayOptions(optionObj, uniques);
    }
    return addOptions(uniques, optionObj);
  });
  return [...uniques.values()];
}

// @note: can pass in getCustomOption func to format options in a custom way
export function getOptions(records, segments, getCustomOption) {
  if (records.length === 0) return {};

  return segments.reduce((options, { value: key }) => {
    const unsorted = getUniqueOptions(records, key, getCustomOption);
    const sorted = unsorted.sort(byObjectValue);

    return {
      ...options,
      [key]: sorted,
    };
  }, {});
}

function ReportFilter({
  isOpen,
  onClose,
  onOpen,
  onSelectionsChange,
  options,
  segments,
  selections,
}) {
  const buttonRef = useRef(null);

  const handleClose = () => {
    onClose();
    buttonRef.current.focus();
  };

  return (
    <>
      <Button
        ref={buttonRef}
        iconLeft="filter"
        label="Filter"
        variant="secondary"
        onClick={onOpen}
      />
      <Filter
        isOpen={isOpen}
        options={options}
        segments={segments}
        selections={selections}
        onClose={handleClose}
        onSelectionsChange={onSelectionsChange}
      />
    </>
  );
}

ReportFilter.propTypes = propTypes;

export default ReportFilter;
