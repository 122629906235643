import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Dropdown,
  TextInput,
  Tooltip,
} from '@makeably/creativex-design-system';
import CreateCampaignModal from 'components/creative_lifecycle/modal/CreateCampaignModal';
import SubmittedCoreAssets from 'components/creative_lifecycle/SubmittedCoreAssets';
import FileDrop from 'components/molecules/FileDrop';
import { addToast } from 'components/organisms/Toasts';
import {
  track,
  useViewPage,
} from 'utilities/mixpanel';
import { getAuthenticityToken } from 'utilities/requests';
import {
  presignedUploadAssetsPath,
  creativeLifecycleCoreAssetUploadsPath,
} from 'utilities/routes';
import styles from './CoreAssetUploader.module.css';

const campaignProps = {
  brand: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.number,
};

const propTypes = {
  brands: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ).isRequired,
  campaigns: PropTypes.arrayOf(PropTypes.shape(campaignProps)).isRequired,
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ).isRequired,
  campaign: PropTypes.shape(campaignProps),
};

const defaultProps = {
  campaign: undefined,
};

function CoreAssetUploader({
  brands,
  campaigns: initialCampaigns,
  campaign: initialCampaign,
  partners,
}) {
  const [assets, setAssets] = useState([]);
  const [campaign, setCampaign] = useState(initialCampaign);
  const [campaigns, setCampaigns] = useState(initialCampaigns);
  const [erroredFilenames, setErroredFilenames] = useState([]);
  // note: fileDropKey used to 'clear' uppy uploader by forcing it to re-initialize
  const [fileDropKey, setFileDropKey] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [partner, setPartner] = useState();
  const [showModal, setShowModal] = useState(false);
  const [submitted, setSubmitted] = useState();
  const [uploadCount, setUploadCount] = useState(0);

  useViewPage();

  const addCampaign = ({
    brand, id, name,
  }) => {
    const newCampaign = {
      brand,
      label: name,
      value: id,
    };
    const newCampaigns = [newCampaign, ...campaigns];

    setCampaign(newCampaign);
    setCampaigns(newCampaigns);
  };

  const resetForm = () => {
    setCampaign(undefined);
    setPartner(undefined);
    setFileDropKey((prevState) => prevState + 1);
    setAssets([]);
    setUploadCount(0);
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    track('submit');
    const response = await fetch(creativeLifecycleCoreAssetUploadsPath(), {
      method: 'POST',
      body: JSON.stringify({
        assets,
        campaign_id: campaign?.value,
        partner_id: partner?.value,
      }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getAuthenticityToken(),
      },
    });

    if (response.ok) {
      const data = await response.json();
      setIsSubmitting(false);
      if (data.success) {
        track('submit_success');
        addToast(`(${assets.length}) Core assets successfully uploaded.`);
      } else {
        addToast(`(${data.errors.length}/${assets.length}) Core assets failed to upload.`, { type: 'error' });
        setErroredFilenames(data.errors);
      }
      setSubmitted({
        assets,
        brand: campaign.brand,
        campaign: campaign.label,
        partner: partner.label,
      });
    } else {
      addToast('Something went wrong. Please reload the page and try again.', { type: 'error' });
    }

    resetForm();
  };

  const onUploadSuccess = (file, url) => {
    const asset = {
      content_type: file.type,
      filename: file.name,
      preview: file.preview,
      s3_filename: url.substring(url.lastIndexOf('/') + 1),
      url,
    };

    setAssets((prevAssets) => [...prevAssets, asset]);
  };

  const onNetworkError = () => {
    const message = 'Error: This looks like a network error, the endpoint might be blocked by an internet provider or a firewall.';
    addToast(message, {
      hasTimeout: false,
      size: 'large',
      type: 'error',
    });
  };

  // note: isFormEmpty is used to disable 'Clear' button
  // to prevent excessive FileDrop re-initializing
  const isFormEmpty = !campaign && !partner && assets.length === 0;
  const isFormComplete = campaign
    && partner
    && assets.length > 0
    && uploadCount === assets.length;

  return (
    <>
      <Card>
        <div className={styles.cardContent}>
          <div className={styles.inputContainer}>
            <div>
              <div className={styles.details}>
                <div className={styles.titleRow}>
                  <h5>New Core Assets</h5>
                  <div className="t-subtitle">
                    <Tooltip ariaLabelSubject="Core Asset" label="Core assets are templates prior to activation" />
                  </div>
                </div>
                <div className="t-body-2">
                  Please upload all final core assets associated with your campaign relevant to
                  channels connected to CreativeX. Only one aspect ratio is needed for each unique
                  asset, and for videos use the longest length.
                </div>
              </div>
              <div className={styles.formElements}>
                <div className={styles.campaignContainer}>
                  <Dropdown
                    disabled={campaigns.length === 0}
                    label="Campaign Name"
                    menuProps={{ size: 'medium' }}
                    options={campaigns}
                    placeholder={campaigns.length > 0 ? 'Select' : 'None'}
                    selected={campaign}
                    size="medium"
                    onChange={(c) => setCampaign(c)}
                  />
                  <Button
                    label="Add New"
                    variant="tertiary"
                    onClick={() => setShowModal(true)}
                  />
                </div>
                <TextInput
                  label="Brand"
                  size="medium"
                  value={campaign?.brand || ''}
                  disabled
                />
                <Dropdown
                  label="Production Partner"
                  menuProps={{ size: 'medium' }}
                  options={partners}
                  selected={partner}
                  size="medium"
                  onChange={(p) => setPartner(p)}
                />
              </div>
            </div>
            <div className="buttonGroup">
              <Button
                disabled={isFormEmpty}
                label="Clear"
                variant="secondary"
                onClick={resetForm}
              />
              <Button
                disabled={!isFormComplete || isSubmitting}
                label="Submit"
                variant="primary"
                onClick={onSubmit}
              />
            </div>
          </div>
          <div className={styles.uploaderContainer}>
            <FileDrop
              // note: key used to 'clear' uppy uploader by forcing it to re-initialize
              key={fileDropKey}
              height={620}
              postUrl={presignedUploadAssetsPath()}
              isPresignedUrl
              onNetworkError={onNetworkError}
              onSuccess={onUploadSuccess}
              onUploadStart={({ fileIDs }) => setUploadCount((prev) => prev + fileIDs.length)}
            />
          </div>
        </div>
        { submitted && (
          <SubmittedCoreAssets
            erroredFilenames={erroredFilenames}
            submitted={submitted}
          />
        ) }
      </Card>
      <CreateCampaignModal
        brandOptions={brands}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onSuccess={addCampaign}
      />
    </>
  );
}

CoreAssetUploader.propTypes = propTypes;
CoreAssetUploader.defaultProps = defaultProps;

export default CoreAssetUploader;
